'use client'

import Image from 'next/image'
import ChristmasLogoImg from './christmas_logo.svg'

export function ChristmasLogo({
  width,
  height,
  scale,
}: {
  width: number
  height: number
  scale?: boolean
}) {
  return (
    <Image
      src={ChristmasLogoImg}
      width={width}
      height={height}
      className={scale ? 'scale-[1.03]' : ''}
      alt="christmas-logo"
    />
  )
}
